.map-container {
    overflow: visible;
    position: relative;
}

.state {
    fill: #1976d2;
    stroke: #fff;
    stroke-width: 1;
    transition: fill 0.3s ease;
}

.state:hover,
.state:active {
    fill: #07145f;
    cursor: pointer;
    stroke-width: 1.5
}

.ut {
    fill: #1976d2;
    stroke: #fff;
    stroke-width: 0.05;
    transition: fill 0.3s ease;
}

.ut:hover,
.ut:active {
    fill: #07145f;
    stroke: #07145f;
    cursor: pointer;
    stroke-width: .5
}